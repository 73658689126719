import React from 'react'
import Select, { components } from 'react-select'

const MultiValueLabel = (props) => {
    const label = props?.data?.value

    const newProps = {
        ...props,
        children: label
    }
    return (
        <components.MultiValueLabel {...newProps} />
    );
};

export default function Filter({
    onApplyFilter,
    onClearAll,
    updateFilters,
    initialFilters,
}) {
    const [filters, setFilters] = React.useState(initialFilters)
    const [changed, setChanged] = React.useState(0)

    const handleSelectFilter = (key) => {
        const newFilters = updateFilters(filters, key)
        setFilters(newFilters)
        setChanged(changed + 1)
    }

    const handleSelectOption = (key, selectedOptions, actionMeta) => {
        const actionType = actionMeta?.action
        if (actionType === 'select-option') {
            const selectedOptionName = actionMeta?.option.value

            const appliedFilters = filters.map(filter => {
                filter.options.map(option => {
                    if (filter.key === key && option.name === selectedOptionName) option.active = !option.active
                    return option
                })
                return filter
            })
            setFilters(appliedFilters)
            onApplyFilter(true, appliedFilters)
        }
        else if(actionType === 'remove-value') {
            const selectedOptionName = actionMeta?.removedValue.value
            handleClearItem(key, selectedOptionName)
        }
        else if (actionType === 'clear') {
            const appliedFilters = filters.map(filter => {
                if (filter.key === key) {
                    filter.options.map(option => {
                        option.active = false
                        return option
                    })
                }

                return filter
            })
            setFilters(appliedFilters)

            let isFilterApplied = false
            appliedFilters.forEach(filter => {
                filter.options.forEach(option => {
                    if (option.active) isFilterApplied = true
                })
            })
    
            if (isFilterApplied) onApplyFilter(true, appliedFilters)
            else handleClearAll()
        }
    }

    const handleClearItem = (key, selectedOptionName) => {
        const appliedFilters = filters.map(filter => {
            filter.options.map(option => {
                if (filter.key === key && option.name === selectedOptionName) option.active = !option.active
                return option
            })
            return filter
        })
        setFilters(appliedFilters)

        let isFilterApplied = false
        appliedFilters.forEach(filter => {
            filter.options.forEach(option => {
                if (option.active) isFilterApplied = true
            })
        })

        if (isFilterApplied) onApplyFilter(true, appliedFilters)
        else handleClearAll()
    }

    const handleClearAll = () => {
        setFilters(filters.map(filterItem => {
            filterItem.options = filterItem.options.map(option => {
                option.active = false
                return option
            })

            return filterItem
        }))
        onClearAll()
    }

    React.useEffect(() => {
        if (initialFilters?.length) setFilters(initialFilters)
    }, [initialFilters])

    return (
        <div className="bg-gray-sm px-4">
            {
                filters?.length > 0 ? (
                    <div className="flex flex-wrap justify-around">
                        {
                            filters.map(filter => (
                                <div className='' key={filter.key} style={{minWidth: '200px'}}>
                                    <div className="mr-2 my-2">
                                        <Select
                                            isMulti
                                            name={filter.name}
                                            placeholder={filter.name}
                                            options={filter.options.map(option => {
                                                return {
                                                    value: option.name,
                                                    label: `${option.name} (${option.qty})`,
                                                    color: '#ff0000'
                                                }
                                            })}
                                            defaultValue={filter.options.filter(option => option.active)
                                                .map(option => {
                                                return {
                                                    value: option.name,
                                                    label: `${option.name} (${option.qty})`
                                                }
                                            })}
                                            onChange={(newValue, actionMeta) => handleSelectOption(filter.key, newValue, actionMeta)}
                                            onMenuOpen={() => handleSelectFilter(filter.key)}
                                            components={{ MultiValueLabel: MultiValueLabel }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: 'var(--primary)',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className="flex items-center justify-center px-2 py-8">
                        <h3 className="text-base">Filtering is not available.</h3>
                    </div>
                )
            }
        </div>
    )
}