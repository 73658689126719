import * as React from "react"
import { graphql } from "gatsby"
import ProductListing from "../components/product-listing"
import { StoreContext } from "../context/store-context"
import { Seo } from "../components/seo/collection-page"
import { Transition } from '@headlessui/react'
import { CheckMarkIcon, MobileFilterIcon } from '../icons'
import Filter from '../components/collections/filter'
import DesktopFilter from '../components/collections/desktop-filter'
import { FILTERS } from '../constants'
import { Layout } from "../components/layout/layout"
import LayoutSwitcher from "../components/collections/layout-switcher"

const appUrl = process.env.GATSBY_APP_URL

export default function ProductTypeIndex({
  data: { collection }
}) {
  const [isFiltered, setIsFiltered] = React.useState(false)
  const [filters, setFilters] = React.useState()
  const [openFilter, setOpenFilter] = React.useState(false)
  const [isTruncated, setIsTruncated] = React.useState(true)
  const [truncateLength, setTruncateLength] = React.useState(200)
  const [products, setProducts] = React.useState(collection?.products)
  const [viewMode, setViewMode] = React.useState('column' || 'list')

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "CollectionPage",
    "@id": collection.shopifyId,
    "name": collection.title,
    "url": `${appUrl}/${collection.handle}`,
    "image": collection?.image?.originalSrc,
    "description": collection?.description,
    "brand": {
      "@type": "Thing",
      "name": "PierceOfArt"
    }
  }

  const { collectionFilters, storeCollectionFilters, clearFilters } = React.useContext(StoreContext)
  const { handle } = collection

  const filterHandler = React.useCallback((appliedFilters) => {
    const BreakException = {}

    const initialProducts = collection?.products
    const filteredProducts = initialProducts.filter(product => {
      let filtered = false
      try {
        appliedFilters.forEach(filter => {
          let isMetaFieldExist = false
          let isFilteredInSameLevel = false
          let isNeedBreak = false
          product.metafields.forEach(metafield => {
            if (metafield.namespace === filter.namespace && metafield.key === filter.key) {
              isMetaFieldExist = true
              filter.options.forEach(option => {
                const valueArr = filter.list ? JSON.parse(metafield.value) : metafield.value
  
                if (option.active && valueArr.includes(option.name)) {
                  filtered = true
                  isFilteredInSameLevel = true
                  setIsFiltered(true)
                  isNeedBreak = false
                }
                else if (option.active && !valueArr.includes(option.name) && !isFilteredInSameLevel) {
                  filtered = false
                  isNeedBreak = true
                }
              })
            }
          })

          if (isNeedBreak) {
            throw BreakException
          }
          
          if (!isMetaFieldExist && filter.options.find(option => option.active)?.active) {
            filtered = false
            throw BreakException
          }
        })
      }
      catch (err) {
        if (err !== BreakException) throw err
      }


      return filtered
    })

    return filteredProducts
  }, [collection])

  const applyFilter = (isHandPicked, appliedFilters) => {
    // store filters
    if (isHandPicked) {
      const filteredAppliedFilters = []
      appliedFilters.forEach((filterItem) => {
        filterItem.options.forEach((optionItem) => {
          if (optionItem.active) {
            filteredAppliedFilters.push({
              key: filterItem.key,
              value: optionItem.name
            })
          }
        })
      })
      storeCollectionFilters(handle, filteredAppliedFilters)
    }

    // filtering
    const filteredProducts = filterHandler(appliedFilters)
    setProducts(filteredProducts)
    setOpenFilter(false)
  }

  const handleClearAll = () => {
    setProducts(collection?.products)
    setOpenFilter(false)
    setIsFiltered(false)
    initFilters()
    clearFilters(handle)
  }

  const updateFilters = React.useCallback((filtersParam, selectedKey) => {
    const tempFilters = filtersParam
    const tmp = JSON.parse(JSON.stringify(filtersParam))
    let isFilterApplied = false
    tmp.forEach(tmpFilter => {
      tmpFilter.options.forEach(option => {
        if (option.active && tmpFilter.key !== selectedKey) isFilterApplied = true
      })
    })
    const tempProducts = isFilterApplied ? filterHandler(tmp.map(filter => {
      if (selectedKey === filter.key) {
        filter.options = filter.options.map(option => {
          option.active = false
          return option
        })
      }

      return filter
    })) : collection?.products


    if (tempProducts.length === 0) return
    
    tempFilters.forEach(tempFilter => {
      tempFilter.options.forEach(option => {
        const qty = tempProducts.filter(product => {
          let isFiltered = false
          product.metafields.forEach(mf => {
            if (mf.namespace === tempFilter.namespace && mf.key === tempFilter.key) {
              const valueArr = tempFilter.list ? JSON.parse(mf.value) : mf.value
              if (valueArr.includes(option.name)) isFiltered = true
            }
          })

          return isFiltered
        }).length
        
        option.qty = qty
      })
    })
    
    setFilters(tempFilters)
    return tempFilters
  }, [filterHandler, collection])

  const initFilters = React.useCallback(() => {
    const tempFilters = FILTERS.SKELETON_FILTERS

    tempFilters.forEach(tempFilter => {
      tempFilter.active = false
      tempFilter.options = []
      collection?.products.forEach(product => {
        product.metafields.forEach(metafield => {
          if (tempFilter.key === metafield.key) {
            tempFilter.active = true
            if (tempFilter.list) {
                const valueArr = JSON.parse(metafield.value)
                valueArr.forEach(item => {
                  if (!tempFilter.options.includes(item)) tempFilter.options.push(item)
                })
            }
            else {
              if (!tempFilter.options.includes(metafield.value)) tempFilter.options.push(metafield.value)
            }
          }
        })
      })
    })
    
    setFilters(
        tempFilters
        .filter(filter => filter.options.length > 0 && filter.active)
        .map(filter => {
            filter.options = filter.options
            .sort((a, b) => {return parseInt(a) - parseInt(b)})
            .map(option => {
                return {
                  name: option,
                  active: false,
                  qty: collection?.products.filter(product => {
                          let isFiltered = false
                          product.metafields.forEach(metafield => {
                            if (metafield.namespace === filter.namespace && metafield.key === filter.key) {
                              const valueArr = filter.list ? JSON.parse(metafield.value) : metafield.value
                              if (valueArr.includes(option)) isFiltered = true
                            }
                          })

                          return isFiltered
                        }).length
                }
            })
            return filter
        })
    )
  }, [collection])

  React.useEffect(() => {
    initFilters()
  }, [initFilters, collection])

  React.useEffect(() => {
    if (openFilter) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [openFilter])

  // set filters and apply from store context
  React.useEffect(() => {
    try {
      const myCollectionFilters = collectionFilters.find(item => item.handle === handle)?.appliedFilters

      if (myCollectionFilters?.length && filters?.length) {
        let appliedFilters = filters
        myCollectionFilters.forEach(filterItem => {
          filters.forEach((filter, filterIndex) => {
            filter.options.forEach((option, optionIndex) => {
              if (filter.key === filterItem.key && option.name === filterItem.value) {
                appliedFilters[filterIndex].options[optionIndex].active = true
              }
            })
          })
        })
        setFilters(appliedFilters)
        applyFilter(false, appliedFilters)
      }
    }
    catch (err) {
      console.log('[err]', err)
    }
  }, [collectionFilters, filters, handle])

  React.useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const width = window.innerWidth
      if (width > 768) setTruncateLength(350)
    }
  }, [setTruncateLength])

  return (
    <Layout>
      <Seo
       title={`${collection.title}`}
       description={`${collection.description.slice(0, 160)}`}
       structuredData={structuredData}
       image={collection?.image?.originalSrc}
      />
      <div className="py-4 sm:px-4 md:px-8 mx-auto max-w-screen-xl">
        <h1 className="text-xl text-center font-bold pb-4">{collection.title}</h1>
        <div className="px-2 mb-3">
          <div className="mb-4 text-left">
            <div className={`text-sm collection-description`} dangerouslySetInnerHTML={{
              __html: isTruncated ? collection.description.slice(0, truncateLength) + '...' : collection?.descriptionHtml
            }}></div>
            {
              collection.description.length > truncateLength && <button className="mt-1 text-sm" onClick={() => setIsTruncated(!isTruncated)}>{isTruncated ? 'Read more +' : 'Read less -'}</button>
            }
          </div>

          <button className="w-full flex sm:hidden items-center justify-center bg-primary uppercase p-3 mb-2 text-center text-white relative" onClick={() => setOpenFilter(true)}>
            <span>Filter / Sort</span>
            {
              isFiltered && <span className="ml-2">
                <CheckMarkIcon fill="#fff" />
              </span>
            }

            <span className="absolute right-3 bottom-3">
              <MobileFilterIcon width={24} height={24} fill="#fff" />
            </span>
          </button>
          
          {/* <button className="w-full bg-tttt uppercase p-3 text-center text-white mb-2">
            <span className="block">*** Free UK Delivery ***</span>
            <span className="block">On Everything</span>
          </button> */}
        </div>

        <div className="hidden sm:block">
          <DesktopFilter
            onApplyFilter={applyFilter}
            onClearAll={handleClearAll}
            updateFilters={updateFilters}
            initialFilters={filters}
          />
        </div>

        <ProductListing products={products} viewMode={viewMode} template="collection" />

        { openFilter && <div className="w-full h-full absolute top-0 left-0 z-10 bg-background-overlay transition ease-in-out duration-700"></div> }
        <Transition
          enter={`transform transition ease-linear duration-150`}
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave={`transform transition ease-linear duration-150`}
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
          show={openFilter}
          as={React.Fragment}
        >
          <div className="w-full h-full fixed z-20 top-0 right-0 bg-transparent flex items-start justify-start">
            <Filter
             onApplyFilter={applyFilter}
             onClearAll={handleClearAll}
             updateFilters={updateFilters}
             initialFilters={filters}
            />
          </div>
        </Transition>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    collection: shopifyCollection(id: { eq: $id }) {
      id
      shopifyId
      title
      handle
      description
      descriptionHtml
      image {
        originalSrc
      }
      products {
        ...ProductCard
      }
    }
  }
`
