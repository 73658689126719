import React from 'react'
import { ChevronRightIcon, CheckMarkIcon, ArrowleftIcon, FilterIcon } from '../../icons'

export default function Filter({
    onApplyFilter,
    onClearAll,
    initialFilters,
    updateFilters,
}) {
    const [filters, setFilters] = React.useState(initialFilters)
    const [filter, setFilter] = React.useState()
    const [openSubFilter, setOpenSubFilter] = React.useState(false)
    const filtersElem = React.useRef()

    const handleSelectFilter = (key) => {
        setFilter(filters.find(filter => filter.key === key))
        updateFilters(filters, key)
        setOpenSubFilter(true)
    }

    const handleSelectOption = (key, selectedOption) => {
        setFilters(filters.map(filter => {
            filter.options.map(option => {
                if (filter.key === key && option.name === selectedOption.name) option.active = !option.active
                return option
            })
            return filter
        }))
    }

    const handleFilter = () => {
        let isFilterApplied = false
        filters.forEach(filter => {
            filter.options.forEach(option => {
                if (option.active) isFilterApplied = true
            })
        })

        if (isFilterApplied) onApplyFilter(true, filters)
        else handleClearAll()
    }

    const handleClear = () => {
        setFilters(filters.map(filterItem => {
            if (filterItem.key === filter.key) {
                filterItem.options = filter.options.map(option => {
                    option.active = false
                    return option
                })
            }
            return filterItem
        }))
    }

    const handleClearAll = () => {
        setFilters(filters.map(filterItem => {
            filterItem.options = filterItem.options.map(option => {
                option.active = false
                return option
            })

            return filterItem
        }))
        onClearAll()
    }

    const handleBackFilter = () => {
        // let isFilterApplied = false
        // filters.forEach(filter => {
        //     filter.options.forEach(option => {
        //         if (option.active) isFilterApplied = true
        //     })
        // })

        // if (isFilterApplied) updateFilters(filters)
        // else initFilters()
        setOpenSubFilter(false)
    }

    React.useEffect(() => {
        if (openSubFilter) {
            filtersElem.current.scrollTop = 0
        }
    }, [openSubFilter])

    return (
        <React.Fragment>
            <div className={`w-full h-full bg-white relative overflow-y-auto text-gray-500`} ref={filtersElem}>
                <div className="flex items-center justify-between border-b bg-white border-b-gray-300 px-4 py-4 sticky top-0">
                    <div>
                        {
                            openSubFilter && filter ? (
                                <div className='flex items-center justify-start'>
                                    <button className='pr-2' onClick={handleBackFilter}>
                                        <ArrowleftIcon width={20} height={20} />
                                    </button>
                                    <h3 className='text-base font-bold uppercase'>{filter.name}</h3>
                                </div>
                            ) : (
                                <div className='flex items-center justify-start'>
                                    <div className='pr-2'>
                                        <FilterIcon width={20} height={20} />
                                    </div>
                                    <h3 className='text-base font-bold uppercase'>Filters</h3>
                                </div>
                            )
                        }
                    </div>
                    <button className='text-gray-400' onClick={openSubFilter ? handleClear : handleClearAll}>{ openSubFilter ? 'Clear': 'Clear all' }</button>
                </div>
                <div className="w-full min-h-filter">
                {
                    openSubFilter && filter ? (
                        filter?.options.map((option, index) => (
                            <div className="border-b border-b-gray-300" key={index}>
                                <button className='w-full flex items-center justify-between p-4' onClick={() => handleSelectOption(filter.key, option)}>
                                    <span>
                                        <h3 className='text-base uppercase'>{ option.name } <span className='text-gray-400'>({ option.qty })</span></h3>
                                    </span>
                                    {
                                        option.active && (
                                            <span>
                                                <CheckMarkIcon />
                                            </span>
                                        )
                                    }
                                    
                                </button>
                            </div>
                        ))
                    ) : (
                        filters?.length > 0 ? (
                            filters.map(filter => (
                                <div className={`border-b border-b-gray-300`} key={filter.key}>
                                    <button className='w-full flex items-center justify-between p-4' onClick={() => handleSelectFilter(filter.key)}>
                                        <div className='text-left'>
                                            <h3 className='text-base uppercase'>{ filter.name }</h3>
                                            <div className='text-xs text-gray-400'>
                                                {
                                                    filter.options
                                                    .filter(option => option.active)
                                                    .map((option, index) => <span key={index}>{index === 0 ? option.name : `, ${option.name}`}</span>)
                                                }
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <ChevronRightIcon />
                                        </div>
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="flex items-center justify-center px-2 py-8">
                                <h3 className="text-base">Filtering is not available.</h3>
                            </div>
                        )
                    )
                }
                </div>
                <div className="p-2 bg-white flex items-center justify-center w-full sticky bottom-0 border-t border-t-gray-300">
                    <button className="border border-gray-300 py-3 px-8 uppercase w-full mx-2 bg-primary text-white" onClick={handleFilter}>View Items</button>
                </div>
            </div>
        </React.Fragment>
    )
}